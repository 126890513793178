import React from 'react'
import PropTypes from 'prop-types'

const getIcon = (name, color) => {
  switch (name) {
    case 'Arrow':
      return (
        <polyline
          points="95 37.5 50 82.5 5 37.5"
          fill="none"
          stroke={color}
          strokeWidth="2"
        />
      )
    default:
      return <path />
  }
}

const Icon = (props) => {
  const { name, color, viewBox, className } = props

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox={viewBox}
      aria-labelledby="title"
      className={className || 'icon'}
    >
      <title id="title">{name}</title>
      {getIcon(name, color)}
    </svg>
  )
}

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  color: PropTypes.string,
  viewBox: PropTypes.string,
}

Icon.defaultProps = {
  viewBox: '0 0 100 100',
}

export default Icon
