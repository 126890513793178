import React from 'react'

const WordmarkBig = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 420 160">
      <g className="word">
        <path
          d="M84.332,55.322,73,37.076l-10.2.035V55.322H58.884V11.113h13.13c8.931,0,14.7,5.154,14.7,13.13a12.09,12.09,0,0,1-9.5,12.219l-.219.057,11.686,18.8Zm-12.58-21.61c6.993,0,10.845-3.363,10.845-9.469,0-6.143-3.953-9.665-10.845-9.665H62.807V33.712Z"
          fill="#ffffff"
        />
        <path
          d="M128.329,55.322l-4.455-11.005h-20.4L99.083,55.322H95.122L113.77,9.944l18.715,45.378Zm-5.893-14.47-8.763-21.773-8.762,21.773Z"
          fill="#ffffff"
        />
        <path
          d="M159.786,55.845a22.5,22.5,0,0,1-16.127-6.485,22.466,22.466,0,0,1-6.37-16.142c0-12.9,9.615-22.629,22.367-22.629,7.144,0,13.093,2.785,17.2,8.056l.047.061-3,2.163-.044-.056a17.317,17.317,0,0,0-14.269-6.694c-10.365,0-18.182,8.21-18.182,19.1,0,11.067,7.784,19.1,18.51,19.1a18.365,18.365,0,0,0,15.123-7.284l.044-.056,3.067,2.164-.048.062C173.649,52.937,167.487,55.845,159.786,55.845Z"
          fill="#ffffff"
        />
        <rect
          x="189.495"
          y="11.113"
          width="3.988"
          height="44.209"
          fill="#ffffff"
        />
        <path
          d="M227.842,55.845c-12.938,0-22.693-9.728-22.693-22.628s9.755-22.628,22.693-22.628,22.694,9.728,22.694,22.628S240.78,55.845,227.842,55.845Zm0-41.726c-10.589,0-18.574,8.21-18.574,19.1s7.985,19.1,18.574,19.1,18.575-8.211,18.575-19.1S238.431,14.119,227.842,14.119Z"
          fill="#ffffff"
        />
        <path
          d="M277.166,55.845c-9.594,0-15.554-5.934-15.554-15.488V11.113h3.923V40.03c0,7.808,4.239,12.286,11.631,12.286S288.8,47.838,288.8,40.03V11.113h3.922V40.357C292.719,49.911,286.76,55.845,277.166,55.845Z"
          fill="#ffffff"
        />
        <path
          d="M318.17,55.845c-6.66,0-12.01-3.079-15.066-8.67l-.036-.066,3.307-1.745.035.064A12.824,12.824,0,0,0,318.1,52.316c6.083,0,9.862-3,9.862-7.831,0-4.891-2.856-7.109-8.48-9.716l-3.6-1.7c-6.494-3.047-10.253-5.994-10.253-12.031,0-6.15,4.725-10.445,11.492-10.445,5.578,0,9.293,1.933,12.046,6.269l.04.062-2.99,1.914-.04-.064c-2-3.262-4.857-4.782-8.99-4.782-4.534,0-7.7,2.843-7.7,6.914,0,4.383,2.548,6.06,7.955,8.6l3.54,1.7c6.908,3.232,10.908,6.42,10.908,13.147C331.889,51.442,326.632,55.845,318.17,55.845Z"
          fill="#ffffff"
        />
        <path
          d="M37.291,33.978V37.32h9.8V47.971L47,48.044a18.665,18.665,0,0,1-9.711,4.133,27.4,27.4,0,0,1-4.34.278c-11-.12-18.95-8.206-18.95-19.314,0-11.012,7.906-19.315,18.391-19.315,7.57,0,11.467,3.078,14,5.836l2.68-2.5c-4.195-4.534-9.613-6.739-16.552-6.739a22.439,22.439,0,0,0-15.94,6.438A22.7,22.7,0,0,0,10,33.141c0,12.878,9.85,22.607,22.951,22.721a31.18,31.18,0,0,0,4.34-.237,23.566,23.566,0,0,0,13.4-5.9V33.978Z"
          fill="#ffffff"
        />
      </g>
      <g className="word">
        <path
          d="M79.539,115.159c-12.938,0-22.694-9.728-22.694-22.628S66.6,69.9,79.539,69.9s22.694,9.728,22.694,22.628S92.477,115.159,79.539,115.159Zm0-41.726c-10.589,0-18.575,8.21-18.575,19.1s7.986,19.1,18.575,19.1,18.574-8.211,18.574-19.1S90.128,73.433,79.539,73.433Z"
          fill="#ffffff"
        />
        <path
          d="M124.081,115.159c-6.66,0-12.011-3.079-15.066-8.67l-.037-.066,3.308-1.744.035.063a12.824,12.824,0,0,0,11.694,6.888c6.083,0,9.862-3,9.862-7.831,0-4.892-2.856-7.11-8.48-9.716l-3.6-1.7c-6.493-3.048-10.253-5.994-10.253-12.032,0-6.15,4.725-10.445,11.492-10.445,5.577,0,9.292,1.933,12.046,6.269l.04.063-2.991,1.914-.039-.065c-2-3.262-4.857-4.782-8.99-4.782-4.534,0-7.7,2.843-7.7,6.914,0,4.384,2.547,6.06,7.954,8.6l3.54,1.705c6.908,3.232,10.908,6.419,10.908,13.146C137.8,110.756,132.543,115.159,124.081,115.159Z"
          fill="#ffffff"
        />
        <path
          d="M149.531,114.636V70.427h12.934c9.069,0,14.7,5.081,14.7,13.261,0,8.139-5.633,13.195-14.7,13.195h-9.011v17.753ZM162.2,93.419c6.993,0,10.844-3.456,10.844-9.731,0-6.226-3.952-9.8-10.844-9.8h-8.749V93.419Z"
          fill="#ffffff"
        />
        <rect
          x="188.701"
          y="70.427"
          width="3.988"
          height="44.209"
          fill="#ffffff"
        />
        <polygon
          points="217.062 114.636 217.062 73.892 203.241 73.892 203.241 70.427 234.741 70.427 234.741 73.892 220.986 73.892 220.986 114.636 217.062 114.636"
          fill="#ffffff"
        />
        <path
          d="M269.018,114.636l-4.455-11h-20.4l-4.389,11h-3.959l18.646-45.378,18.717,45.378Zm-5.891-14.47-8.764-21.773L245.6,100.166Z"
          fill="#ffffff"
        />
        <polygon
          points="283.874 114.636 283.874 70.427 287.796 70.427 287.796 111.171 308.497 111.171 308.497 114.636 283.874 114.636"
          fill="#ffffff"
        />
        <rect
          x="322.685"
          y="70.427"
          width="3.988"
          height="44.209"
          fill="#ffffff"
        />
        <polygon
          points="351.047 114.636 351.047 73.892 337.226 73.892 337.226 70.427 368.726 70.427 368.726 73.892 354.97 73.892 354.97 114.636 351.047 114.636"
          fill="#ffffff"
        />
        <polygon
          points="390.74 114.636 390.74 96.92 375.273 70.427 379.625 70.427 392.735 92.996 405.779 70.464 410 70.427 394.752 96.837 394.728 114.636 390.74 114.636"
          fill="#ffffff"
        />
        <polygon
          points="42.705 70.427 42.705 89.625 17.429 89.625 17.429 70.427 13.587 70.427 13.587 89.688 13.586 89.688 13.586 114.803 17.51 114.803 17.51 92.965 42.705 92.965 42.705 114.803 46.507 114.803 46.507 70.427 42.705 70.427"
          fill="#ffffff"
        />
      </g>
      <g className="word">
        <path
          d="M51.368,146.078H43.354l-1.539,3.688H38.679l8.653-20.153h.174l8.683,20.153H52.907Zm-6.881-2.73h5.749l-2.846-6.91Z"
          fill="#d29129"
        />
        <path
          d="M64.463,136.786v12.98h-2.9V129.729h.233L74.452,143V130.02h2.9v20.036h-.232Z"
          fill="#d29129"
        />
        <path
          d="M95.387,146.078H87.373l-1.539,3.688H82.7l8.654-20.153h.174l8.683,20.153H96.926Zm-6.882-2.73h5.749l-2.845-6.91Z"
          fill="#d29129"
        />
        <path
          d="M103.168,139.893A9.945,9.945,0,0,1,113.3,129.759a9.262,9.262,0,0,1,7.492,3.136l-2.149,2.032a6.5,6.5,0,0,0-5.43-2.351c-4.007,0-6.824,3.136-6.824,7.317a6.988,6.988,0,0,0,7.347,7.317,8.351,8.351,0,0,0,4.791-1.248v-3.978h-5.285v-2.759h8.16v8.218a11.336,11.336,0,0,1-7.666,2.584C107.669,150.027,103.168,145.73,103.168,139.893Z"
          fill="#d29129"
        />
        <path
          d="M127.733,130.02h12.574v2.759H130.84v5.662h8.392v2.7H130.84v5.865h9.873v2.759h-12.98Z"
          fill="#d29129"
        />
        <path
          d="M146.954,129.613h.146l9.64,11.122,9.525-11.122h.145v20.153h-2.933V136.96L156.74,145H156.6l-6.707-8.044v12.806h-2.934Z"
          fill="#d29129"
        />
        <path
          d="M173.319,130.02h12.574v2.759h-9.466v5.662h8.391v2.7h-8.391v5.865H186.3v2.759h-12.98Z"
          fill="#d29129"
        />
        <path
          d="M195.445,136.786v12.98h-2.9V129.729h.232L205.434,143V130.02h2.9v20.036h-.232Z"
          fill="#d29129"
        />
        <path
          d="M219.632,132.779h-5.866V130.02H228.6v2.759h-5.865v16.987h-3.107Z"
          fill="#d29129"
        />
        <path
          d="M13.587,129.651h.145l9.64,11.122L32.9,129.651h.145V149.8H30.109V137l-6.737,8.044h-.145L16.519,137V149.8H13.587Z"
          fill="#d29129"
        />
      </g>
    </svg>
  )
}

export default WordmarkBig
