import React from 'react'
import { motion } from 'framer-motion'

const monogramAnim = {
  default: {
    y: '75%',
    opacity: 0,
    transition: { staggerChildren: 0.1, staggerDirection: -1 },
  },
  show: {
    y: '0%',
    opacity: 1,
    transition: {
      staggerChildren: 0.5,
      delayChildren: 0.25,
      delay: 0.75,
      y: {
        duration: 1.5,
        ease: [0.25, 1, 0.5, 1],
      },
      opacity: {
        duration: 0.75,
      },
    },
  },
  hide: {
    y: '75%',
    opacity: 0,
    transition: {
      staggerChildren: 0.5,
      delayChildren: 0.25,
      y: {
        duration: 1,
        ease: [0.5, 0, 0.75, 0],
      },
      opacity: {
        duration: 0.75,
      },
    },
  },
}

const markAnim1 = {
  default: {
    y: '5em',
  },
  show: {
    y: '0em',
    transition: {
      y: {
        duration: 2,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
}

const markAnim2 = {
  default: {
    y: '5em',
  },
  show: {
    y: '0em',
    transition: {
      y: {
        duration: 2,
        ease: [0.25, 1, 0.5, 1],
      },
    },
  },
}

const markGradient = {
  shimmer: {
    x1: ['0%', '200%'],
    x2: ['-100%', '100%'],
    y1: ['100%', '-100%'],
    y2: ['200%', '0%'],
    transition: {
      delay: 0.5,
      ease: 'linear',
      duration: 2,
    },
  },
}

const Monogram = ({ triggerAnimation }) => {
  return (
    <motion.div
      className="monogram"
      variants={monogramAnim}
      initial="default"
      animate="show"
      exit="hide"
      onClick={() => triggerAnimation(false)}
    >
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 220 240">
        <defs>
          <motion.linearGradient
            id="linear"
            x1="0"
            y1="0"
            x2="100%"
            y2="100%"
            gradientUnits="userSpaceOnUse"
            variants={markGradient}
            animate="shimmer"
          >
            <stop offset="0" stopColor="#d29129"></stop>
            <stop offset="0.35" stopColor="#d29129"></stop>
            <stop offset="0.5" stopColor="#fff"></stop>
            <stop offset="0.65" stopColor="#d29129"></stop>
            <stop offset="1" stopColor="#d29129"></stop>
          </motion.linearGradient>
        </defs>
        <g className="word">
          <path
            d="M104.489,38.112l6.958-6.5C100.556,19.838,86.491,14.114,68.476,14.114A58.247,58.247,0,0,0,27.1,30.828C16.071,41.764,10,56.778,10,73.1c0,33.433,25.57,58.692,59.583,58.987v-8.845c-28.564-.312-49.2-21.3-49.2-50.142,0-28.586,20.526-50.143,47.747-50.143C87.785,22.96,97.9,30.953,104.489,38.112Z"
            fill="#ffffff"
          />
          <path
            d="M115.644,75.277H80.851v8.675h25.434v27.653l-.224.189c-6.894,5.794-15.144,9.308-25.21,10.731v8.95c13.7-1.618,24.779-6.495,34.793-15.31Z"
            fill="url(#linear)"
          />
        </g>
        <motion.g className="word" variants={markAnim1}>
          <rect
            x="136.854"
            y="15.485"
            width="9.874"
            height="48.523"
            fill="#ffffff"
          />
          <polygon
            points="210 130.723 210 15.485 200.129 15.485 200.129 65.341 157.997 65.341 157.997 74.014 200.129 74.014 200.129 130.723 210 130.723"
            fill="#ffffff"
          />
          <polygon
            points="136.854 75.277 136.956 130.723 146.83 130.723 146.728 75.277 136.854 75.277"
            fill="url(#linear)"
          />
        </motion.g>
        <motion.g className="word" variants={markAnim2}>
          <path
            d="M137,144.233H80.85v41.653h65.98V144.233Zm-.033,31.738H90.712V154.148h46.256Z"
            fill="url(#linear)"
          />
        </motion.g>
      </svg>
    </motion.div>
  )
}

export default Monogram
