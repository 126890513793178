import React from 'react'
import { useStaticQuery } from 'gatsby'

function GHMMonogram() {
    const data = useStaticQuery(graphql`
    {
      GHMMono: file(relativePath: { eq: "GHM_Monogram_RGB_N.png" }) {
        publicURL
      }
    }
  `)
   const { GHMMono } = data
    return (
        <img src={GHMMono.publicURL} alt="Gracious Hospitality Management" className='max-w-50' />
    )
}
export default GHMMonogram