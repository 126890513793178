import React from 'react'
import { motion } from 'framer-motion'

const statementAnim = {
  default: {
    y: '50%',
    opacity: 0,
  },
  show: {
    y: '0%',
    opacity: 1,
    transition: {
      y: {
        delay: 0.75,
        duration: 1.5,
        ease: [0.33, 1, 0.68, 1],
      },
      opacity: {
        delay: 0.75,
        duration: 0.5,
      },
    },
  },
  hide: {
    y: '50%',
    opacity: 0,
    transition: {
      y: {
        duration: 1.5,
        ease: [0.32, 0, 0.67, 0],
      },
      opacity: {
        delay: 0.75,
        duration: 0.5,
      },
    },
  },
}

const rotateUp = {
  default: {
    rotateX: '-45deg',
    transition: {
      duration: 2,
      staggerChildren: 0.15,
      delayChildren: 0.5,
    },
  },
  show: {
    rotateX: '0deg',
    transition: {
      duration: 2,
      staggerChildren: 0.15,
      delayChildren: 0.5,
    },
  },
  hide: {
    rotateX: '-45deg',
    transition: {
      duration: 2,
      staggerChildren: 0.15,
      staggerDirection: -1,
    },
  },
}

const variants = {
  default: {
    y: '1.5em',
    opacity: 0,
  },
  show: {
    y: '0em',
    opacity: 1,
    transition: {
      duration: 1,
      ease: [0.25, 1, 0.5, 1],
    },
  },
  hide: {
    y: '1.5em',
    opacity: 0,
    transition: {
      duration: 0.5,
      ease: [0.5, 0, 0.75, 0],
    },
  },
}

const isAnimating = (state) => {
  if (typeof document !== `undefined`) {
    document.body.classList.toggle('is-animating', state)
  }
}

const Statement = () => {
  return (
    <motion.div
      className="details--content"
      initial="default"
      animate="show"
      exit="hide"
      variants={statementAnim}
    >
      <div className="statement">
        <div className="statement--wrapper">
          <motion.div
            className="statement--inner"
            initial="default"
            animate="show"
            exit="hide"
            variants={rotateUp}
            onAnimationComplete={() => isAnimating(false)}
          >
            <motion.div className="statement--content">
              <p>
                <motion.span variants={variants}>
                  Gracious Hospitality Management is first and foremost an
                  outstanding hospitality company that conceptualizes{' '}
                </motion.span>
                <br />
                <motion.span variants={variants}>
                  Michelin-starred and James Beard nominated restaurants.{' '}
                </motion.span>
                <br />
                <motion.span variants={variants}>
                  We focus on both passion and profitability.
                </motion.span>
              </p>
              <p>
                <motion.span variants={variants}>
                  GHM has been honored with receiving a Michelin star eight
                  years in a row for its restaurants:{' '}
                </motion.span>
                <br />
                <motion.span variants={variants}>
                  Piora, Cote Korean Steakhouse &amp; COTE Miami.
                </motion.span>
              </p>
              <p>
                <motion.span variants={variants}>
                  GHM’s portfolio also includes three nominations from the James
                  Beard Foundation:{' '}
                </motion.span>
                <br />
                <motion.span variants={variants}>
                  2017 Best New Restaurant, 2018 Outstanding Wine Program &amp;
                  2019 Outstanding Wine Program.
                </motion.span>
              </p>
              <p>
                <motion.span variants={variants}>
                  Both restaurants are known for their outstanding food, wine
                  and hospitality spanning across European and Asian cuisine.
                </motion.span>
              </p>
            </motion.div>
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
}

export default Statement
