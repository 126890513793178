import React from 'react'
import { motion } from 'framer-motion'
import GHMMonogram from '../components/ghm-monogram'
import WordmarkBig from './wordmark-big'

const rotateDown = {
  default: {
    rotateX: '65deg',
  },
  show: {
    rotateX: '0deg',
    transition: {
      duration: 2,
    },
  },
  hide: {
    rotateX: '65deg',
    transition: {
      duration: 2,
    },
  },
}

const ComingSoon = ({ endAnimation }) => {
  const soonAnim = {
    default: {
      y: '-25%',
      opacity: 0,
    },
    show: {
      y: '0%',
      opacity: 1,
      transition: {
        y: {
          delay: 0.75,
          duration: 1,
          ease: [0.33, 1, 0.68, 1],
        },
        opacity: {
          delay: 1,
          duration: 0.75,
        },
      },
    },
    hide: {
      y: '-25%',
      opacity: 0,
      transition: {
        y: {
          duration: 1,
          ease: [0.32, 0, 0.67, 0],
        },
        opacity: {
          delay: 0.25,
          duration: 0.75,
        },
      },
    },
  }
  return (
    <motion.div
      className="intro--content"
      initial="default"
      animate="show"
      exit="hide"
      variants={soonAnim}
    >
      <div className="soon">
        <div className="soon--inner">
          <motion.div
            className="soon--graphic"
            initial="default"
            animate="show"
            exit="hide"
            variants={rotateDown}
            onAnimationComplete={() => endAnimation()}
          >
            {/* <Graphic /> */}
            <GHMMonogram />
            <WordmarkBig />
          </motion.div>
        </div>
      </div>
    </motion.div>
  )
}

// const Graphic = () => {
//   return (
//     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 520">
//       <path
//         d="M124.344,254.136C55.707,254.136,0,201.669,0,129.434S54.63,4.732,122.908,4.732c42.767,0,70.491,14.734,94.516,44.252l-2.871,2.153c-22.64-28.8-50.314-43.175-91.645-43.175C56.823,7.962,3.589,57.956,3.589,129.434S57.82,250.906,124.344,250.906c43.125,0,75.475-15.452,96.7-45.638l2.871,2.154C201.972,238.326,168.915,254.136,124.344,254.136Z"
//         fill="#ffffff"
//       />
//       <path
//         d="M472.242,129.434c0,72.235-55.707,124.7-124.712,124.7s-124.7-52.467-124.7-124.7,55.707-124.7,124.7-124.7S472.242,57.2,472.242,129.434Zm-3.6,0c0-71.478-54.261-121.472-121.113-121.472S226.416,57.956,226.416,129.434,280.688,250.906,347.53,250.906,468.643,200.952,468.643,129.434Z"
//         fill="#ffffff"
//       />
//       <path
//         d="M706.564,11.192,600.184,165.711,493.805,11.192V251.624h-3.589V1.133h.718L600.184,159.64,709.435,1.133h.728V251.624h-3.6Z"
//         fill="#ffffff"
//       />
//       <path d="M741.067,7.244h3.618v244.38H741.1Z" fill="#ffffff" />
//       <path
//         d="M775.589,1.133h.728L951.3,247.676V7.244h3.6V257.735h-.718L779.158,11.192V251.624h-3.569Z"
//         fill="#ffffff"
//       />
//       <path
//         d="M1200,144.527v76.921c-21.2,18.692-49.237,33.067-94.516,33.067-69.006,0-125.43-52.836-125.43-125.071S1035.78,4.373,1102.972,4.373c40.962,0,70.441,14.734,91.276,38.091l-2.512,2.154C1171.608,21.978,1142.5,7.6,1103.011,7.6c-65.047,0-119.319,51.749-119.319,121.831s54.989,121.831,121.832,121.831c43.484,0,70.78-13.657,90.927-31.262V147.767h-80.5v-3.24Z"
//         fill="#ffffff"
//       />
//       <path
//         d="M234.142,513.171c43.126,0,67.919-23.726,67.919-57.82,0-31.991-17.944-46.366-57.82-66.493l-19.4-9.7c-34.891-17.615-50.682-31.273-50.682-62.536,0-30.9,24.8-49.955,55.707-49.955,34.143,0,48.16,15.811,58.936,33.068l-2.871,1.794c-10.417-16.529-23.716-31.622-56.065-31.622-28.751,0-52.109,17.945-52.109,46.715,0,29.827,15.452,42.767,48.849,59.655L246,385.937c41.332,20.845,59.665,35.938,59.665,69.364,0,35.57-26.239,61.449-71.518,61.449-40.972,0-64.33-22.281-78.346-46l2.871-1.8C172.324,491.967,194.605,513.171,234.142,513.171Z"
//         fill="#ffffff"
//       />
//       <path
//         d="M566.579,391.7c0,72.235-55.707,124.7-124.712,124.7s-124.7-52.467-124.7-124.7,55.677-124.713,124.7-124.713S566.579,319.453,566.579,391.7Zm-3.6,0c0-71.518-54.271-121.473-121.113-121.473S320.753,320.141,320.753,391.7s54.271,121.472,121.114,121.472S562.98,463.217,562.98,391.7Z"
//         fill="#ffffff"
//       />
//       <path
//         d="M823.181,391.7c0,72.235-55.707,124.7-124.7,124.7S573.767,463.934,573.767,391.7s55.706-124.713,124.712-124.713S823.181,319.453,823.181,391.7Zm-3.589,0c0-71.518-54.271-121.473-121.113-121.473S577.365,320.141,577.365,391.7s54.262,121.472,121.114,121.472S819.592,463.217,819.592,391.7Z"
//         fill="#ffffff"
//       />
//       <path
//         d="M848.363,263.4h.718l175.026,246.533V269.509h3.589V520h-.718L851.932,273.456V513.889h-3.569Z"
//         fill="#ffffff"
//       />
//     </svg>
//   )
// }

export default ComingSoon
