import React, { useState, useEffect } from 'react'
import { AnimatePresence, motion } from 'framer-motion'

// site layout
import SEO from '../components/seo'
import ComingSoon from '../components/soon'

import Monogram from '../components/monogram'
import Statement from '../components/statement'

import Icon from '../components/icon'



const actionAnim = {
  default: {
    y: '-1em',
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1.5,
      duration: 1.5,
      ease: [0.25, 1, 0.5, 1],
    },
  },
  hide: {
    y: '-100%',
    opacity: 0,
    transition: {
      y: {
        duration: 1,
      },
      opacity: {
        duration: 0.375,
      },
    },
  },
}

const footerAnim = {
  default: {
    y: '150%',
    opacity: 0,
  },
  show: {
    y: 0,
    opacity: 1,
    transition: {
      delay: 1.25,
      y: {
        duration: 1,
        ease: [0.25, 1, 0.5, 1],
      },
      opacity: {
        duration: 0.75,
      },
    },
  },
  hide: {
    y: '150%',
    opacity: 0,
    transition: {
      y: {
        duration: 1,
        ease: [0.5, 0, 0.75, 0],
      },
      opacity: {
        duration: 0.75,
      },
    },
  },
}

function checkOverflow(el) {
  let curOverflow = el.style.overflow
  if (!curOverflow || curOverflow === 'visible') el.style.overflow = 'hidden'

  let isOverflowing =
    el.clientWidth < el.scrollWidth || el.clientHeight < el.scrollHeight
  el.style.overflow = curOverflow

  return isOverflowing
}

const IndexPage = ({ location }) => {
  const [showDetails, setShowDetails] = useState(false)

  const _setHeight = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  const _onWindowResize = () => {
    _setHeight()
  }

  useEffect(() => {
    _onWindowResize()
    window.addEventListener('resize', _onWindowResize)
    _setHeight()
  })

  const triggerScroll = (e) => {
    const delta = e.nativeEvent.wheelDelta

    if (
      !document.body.classList.contains('is-animating') &&
      !checkOverflow(document.body)
    ) {
      if (delta < 0) {
        triggerAnimation(true)
        window.scrollTo(0, 0)
      } else {
        triggerAnimation(false)
      }
    }
  }

  useEffect(() => {
    if (typeof document !== `undefined`) {
      document.body.classList.add('is-animating')
    }
  }, [showDetails])

  const triggerAnimation = (state) => {
    if (typeof document !== `undefined`) {
      document.body.classList.add('is-animating')
    }

    setTimeout(() => {
      setShowDetails(state)
    }, 50)
  }

  const endAnimation = () => {
    if (typeof document !== `undefined` && !showDetails) {
      document.body.classList.remove('is-animating')
    }
  }

  return (
    <>
      <SEO />
      <main role="main" id="content">
        {/* onWheel={(e) => triggerScroll(e)} */}
        <AnimatePresence>
          {!showDetails && (
            <motion.div key="intro" className="intro">
              {/* <Wordmark /> */}
              <ComingSoon endAnimation={endAnimation} />
              <motion.div
                initial="default"
                animate="show"
                exit="hide"
                variants={actionAnim}
                className="intro--action"
              >
                <h2>Coming Soon</h2>
                <p>
                  In the meantime, please visit us at <br />
                  <a href="https://www.cotekoreansteakhouse.com" target="_blank">
                    cotekoreansteakhouse.com
                  </a>{' '}
                  or{' '}
                  <a href="https://www.coqodaq.com" target="_blank">
                    coqodaq.com
                  </a>
                </p>

                {/* <button
                  aria-label="Learn More"
                  onClick={() => triggerAnimation(true)}
                >
                  <span>Learn More</span>
                  <Icon name="Arrow" />
                </button> */}
              </motion.div>
            </motion.div>
          )}

          {showDetails && (
            <motion.div key="details" className="details">
              <Monogram triggerAnimation={triggerAnimation} />
              <Statement />

              <motion.div
                initial="default"
                animate="show"
                exit="hide"
                variants={footerAnim}
                className="details--footer"
              >
                <p>Gracious Hospitality Management</p>
              </motion.div>
            </motion.div>
          )}
        </AnimatePresence>
      </main>
    </>
  )
}

export default IndexPage
